<template>
<footer class="footer">
      <div class="container-sm">
        <div class="row">
          <div class="col-md-12 col-lg-5">

            <div class="footerLogo">
            <a href="#/" title="Postmedia Parcel Services">
             <img src="../assets/logo.png" alt="Postmedia Parcel Services Logo" style="max-width: 282px;">
            </a>
            </div>
          </div>
          <div class="col-md-12 col-lg-7">
            <div class="footerMenu">
              <ul>
              <div class="menu-footer-menu-container">
                  <ul id="menu-footer-menu" class="menu"><li id="menu-item-224" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-224"><a href="#/track-your-shipment">Track Your Shipment</a></li>
                    <li id="menu-item-78" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-78"><a href="#/partners">Business Solutions</a></li>
                    <li id="menu-item-76" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-76"><a href="#/couriers">Delivery Agents</a></li>
                    <li id="menu-item-79" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-79"><a href="#/support">Support</a></li>
                    <li id="menu-item-77" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-77"><a href="#/about-postmedia">About Us</a></li>
                </ul>
                </div>              
                </ul>
				<ul>
                <li><a href="#/copyright" title="Copyright and Permissions">Copyright</a></li>
                <li><a href="#/google-apps-policy" title="Privacy Policy">Mobile App Privacy Policy</a></li>
                <li><a href="https://www.postmediasolutions.com/" title="Advertise with us" target="_blank">Advertise With Us</a></li>
                <li><a href="https://www.postmedia.com/privacy-statement/" target="_blank" title="Privacy Policy">Privacy Policy</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <footer class="footer">
      <div class="container-sm">
		<div class="disclaimer" style="font-size:11px;opacity:0.5;color:#fff;text-align:justify;padding-left:7%;padding-bottom: 15px;">
            <!-- 			  <p>
				DISCLAIMER: © Postmedia Network Canada Corp. 2010-2021. All rights reserved.<br>
					This website is the property of Postmedia Network Canada Corp. (“Postmedia”). All information and content or other material (collectively, “Content”) found in the pages of this web site is protected under the copyright laws of Canada and other countries. Unless otherwise specified, no person has permission to copy, redistribute, reproduce or republish in any form any Content found on the pages in this web site. This includes electronically reproducing by “uploading” or “downloading” or otherwise transmitting onto the Internet or any other local or international computer system. Any infringement of the rights of Postmedia may result in appropriate legal action.<br>

					If you require further information on a permitted use or license to reproduce or republishing any Content, kindly address your inquiry to webmaster@postmedia.com. Postmedia disclaims any and all liability for any consequences whatsoever which may result from any unauthorized reproduction or use of the Content of this web site.
			</p> -->
		</div>
        <div class="row">
          <div class="col-md-12 col-lg-5">

            <div class="footerLogo">
            <a href="https://www.postmedia.com/" title="Postmedia" target="_blank">
             <img src="../assets/footerpmlogo.png" alt="Postmedia Logo">
            </a>
            </div>
          </div>
          <div class="col-md-12 col-lg-7">
            <p class="copyright-text" style="font-size:11px;opacity:0.5;color:#fff;text-align:left;padding-left:7%">
					365 Bloor Street East, Toronto, Ontario, M4W 3L4<br>
					© 2022 Postmedia Network Inc. All rights reserved. Unauthorized distribution, transmission or republication strictly prohibited.
			</p>
          </div>
        </div>
      </div>
    </footer>

</template>

<script>

export default {
  name: 'page-footer',
  components: {
    
  }
}
</script>
